import { Button, useCallbackRef } from "@faxi/web-component-library";
import {
  FormField,
  FormRef,
  StepsContext,
  useFormRefValues,
} from "@faxi/web-form";
import parse from "html-react-parser";
import { FC, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  FormActions,
  FormStep,
  NumberInputField,
  VehicleStepHeader,
} from "components";
import { useAnalytics } from "providers";
import {
  breakTranslationNewlines,
  convertToNumber,
  formatCurrency,
} from "utils";

type FormValue = {
  loanAmount: string;
  cashDeposit: string;
};

export const MIN_LOAN_AMOUNT = 2500;
export const MAX_LOAN_AMOUNT = 175000;

const stepValidations = (
  t: (message: string, options: Record<string, string>) => string,
) => ({
  loanAmount: () => [
    (value: string | number) => {
      const loanAmount = convertToNumber(value);

      const loanAmountIsValid =
        loanAmount <= MAX_LOAN_AMOUNT && loanAmount >= MIN_LOAN_AMOUNT;

      const errorMessage = t("error_enter_number_between", {
        min: formatCurrency(MIN_LOAN_AMOUNT),
        max: formatCurrency(MAX_LOAN_AMOUNT),
      });

      return loanAmountIsValid ? "" : errorMessage;
    },
  ],
  deposit: (loanAmount: number) => [
    (value: string | number) => {
      const deposit = convertToNumber(value);

      const maxDeposit = loanAmount;
      const minDeposit = 0;

      const depositIsValid = deposit <= maxDeposit && deposit >= minDeposit;

      const errorMessage = t("error_enter_number_between", {
        min: formatCurrency(minDeposit),
        max: formatCurrency(maxDeposit),
      });

      return depositIsValid ? "" : errorMessage;
    },
  ],
});

const Step1: FC = () => {
  const [form, formRef] = useCallbackRef<FormRef>();

  const { t } = useTranslation();
  const { loanAmount } = (useFormRefValues(form, "loanAmount") || {}) as Record<
    "loanAmount",
    string
  >;

  const { nextPage } = useContext(StepsContext);
  const analytics = useAnalytics();
  const location = useLocation();

  const handleTrackEvent = useCallback(() => {
    analytics.track("Next Step", {
      stepCompleted: location.pathname,
    });
  }, [location.pathname, analytics]);

  const handleSubmit = useCallback(
    async (data: FormValue) => {
      handleTrackEvent();
      return nextPage(data);
    },
    [nextPage, handleTrackEvent],
  );

  return (
    <VehicleStepHeader
      title={parse(
        breakTranslationNewlines(t(`landingpage_card_subtitle_no_data`)),
      )}
    >
      <FormStep ref={formRef} onSubmit={handleSubmit}>
        <FormField
          name="loanAmount"
          label={t("outcome_tab_loanamount")}
          validate={stepValidations(t).loanAmount()}
          component={NumberInputField}
        />

        <FormField
          name="cashDeposit"
          label={t("startloan_loandeposit_text")}
          validate={stepValidations(t).deposit(convertToNumber(loanAmount))}
          component={NumberInputField}
        />
        <FormActions>
          <Button type="submit">{t("next")}</Button>
        </FormActions>
      </FormStep>
    </VehicleStepHeader>
  );
};

export default Step1;
