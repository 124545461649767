import classNames from "classnames";
import React, { ReactNode, useCallback, useEffect, useState } from "react";

import { CALL_US_PHONE, MAIL_US_HREF, StorageKeys } from "common";
import { BaseHeader, CallUs, EmailUs, Loader } from "components";
import { StyledHr } from "components/atoms/BaseHeader/BaseHeader.styled";
import { useAnalytics, useVehicle } from "providers";
import { storageService } from "services";
import {
  LoanAmountStatus,
  LoanApprovalResponse,
  LoanApprovalSingleResponse,
} from "types";
import { formatCurrency } from "utils";

import Tabs from "components/atoms/Tabs";
import { getSaveDataResponse } from "utils/getSaveDataResponse";
import { StyledLoanApproved } from "./LoanApproved.styled";

import { useTranslation } from "react-i18next";
import { EmphasizedValue, List, ListItem } from "../../Result.styled";
import { prepareResultDisplayedTrackEvent } from "../../prepareResultDisplayedTrackEvent";

export type LoanDetailProps = {
  label: string;
  value: ReactNode;
  hasPrimaryColor?: boolean;
};

const LoanDetail: React.FC<LoanDetailProps> = ({
  label,
  value,
  hasPrimaryColor,
}) => (
  <ListItem className="ffc-loan-detail">
    {label}{" "}
    <EmphasizedValue hasPrimaryColor={hasPrimaryColor}>{value}</EmphasizedValue>
  </ListItem>
);

const LOAN_APPROVED_DATA = {
  emailUsHref: MAIL_US_HREF,
  callUsPhoneText: CALL_US_PHONE.TEXT,
  callUsHref: CALL_US_PHONE.HREF,
} as const;

export type LoanApprovedProps = {
  personalLoanOfferText: string;
  preApprovedText?: string;
  postApprovedText?: string;
};

const LoanApproved: React.FC<LoanApprovedProps> = ({
  personalLoanOfferText,
  preApprovedText,
  postApprovedText,
}) => {
  const { askingAmount, fullAmount } =
    storageService.getItem<LoanApprovalResponse>(
      StorageKeys.LOAN_AMOUNT_RESULT_STORAGE_KEY,
    );
  const analytics = useAnalytics();
  const { t } = useTranslation();

  const { data, isLoading } = useVehicle();

  const [activeTab, setActiveTab] = useState(0);

  const selectedLoan = activeTab === 0 ? askingAmount : fullAmount!;
  const upToAmount = Math.max(
    askingAmount.loanAmount ?? 0,
    fullAmount?.loanAmount ?? 0,
  );

  const { referenceNumber } = getSaveDataResponse() as {
    referenceNumber: string;
  };

  const handleResultDisplayedTrackEvent = useCallback(
    (loanApprovalResponse: LoanApprovalSingleResponse) => {
      const eventBody = prepareResultDisplayedTrackEvent({
        ...loanApprovalResponse,
        referenceNumber,
      });
      analytics.track("Results Displayed", eventBody);
    },
    [analytics, referenceNumber],
  );

  const showGreatNews = askingAmount.status === LoanAmountStatus.APPROVED;

  const nextStepMessages = t("outcome_nextsubtext")
    .split("\\n")
    .filter(Boolean);
  const disclaimerMessages = t("outcome_footer_disclaimer").split("\\n");
  const fullLoanTabHeaderMessages = t("outcome_tab_fullloan").split("\\n");

  useEffect(() => {
    if (askingAmount) {
      handleResultDisplayedTrackEvent(askingAmount);
    }
  }, [askingAmount, handleResultDisplayedTrackEvent]);

  if (isLoading) return <Loader />;

  return (
    <StyledLoanApproved className="fc-loan-approved">
      <BaseHeader className="fc-loan-approved__header" />

      <div className="fc-loan-approved__image-wrapper">
        {data && (
          <img src={data.thumbnail} alt="Vechicle Details" loading="eager" />
        )}
        <div className="fc-loan-approved__image-overlay">
          {showGreatNews && (
            <div className="fc-loan-approved__image-overlay__great-news">
              <img src="/icons/check.svg" alt="Check icon" />
              <strong>{t("outcome_title")}</strong>
            </div>
          )}
          <p className={classNames(showGreatNews && "fc-loan-offer-text")}>
            {personalLoanOfferText}
          </p>
          {preApprovedText && (
            <p className="fc-loan-approved__image-overlay__pre-approved">
              {preApprovedText}
            </p>
          )}
          <div className="fc-loan-approved__image-overlay__price">
            <p>{formatCurrency(upToAmount)}</p>
            <div className="image-overlay__bg-animation" />
          </div>
          {postApprovedText && <p>{postApprovedText}</p>}
        </div>
      </div>

      <div className="fc-loan-approved__subheader">
        <h2>{t("outcome_loanestimation")}</h2>

        {fullAmount ? (
          <Tabs
            tabs={[
              <p>{t("outcome_tab_askingloan")}</p>,
              <div>
                <p className="fc-loan-approved__subheader__full">
                  {fullLoanTabHeaderMessages[0]}
                </p>
                <small>{fullLoanTabHeaderMessages[1]}</small>
              </div>,
            ]}
            activeTab={activeTab}
            onTabClick={setActiveTab}
          />
        ) : (
          <StyledHr />
        )}
      </div>

      <List className="element-max-width">
        {data && activeTab === 0 && selectedLoan.purchasePrice === 0 && (
          <LoanDetail
            label={t("Outcome_tab_purchaseprice")}
            value={formatCurrency(selectedLoan.purchasePrice)}
          />
        )}

        {activeTab === 1 && (
          <LoanDetail
            label={t("outcome_tab_maxloan")}
            value={formatCurrency(selectedLoan.loanAmount)}
          />
        )}

        {activeTab === 0 && (
          <>
            <LoanDetail
              label={t("outcome_tab_deposit")}
              value={formatCurrency(selectedLoan.deposit)}
            />

            <LoanDetail
              label={t("outcome_tab_loanamount")}
              value={formatCurrency(selectedLoan.loanAmount)}
            />
          </>
        )}

        <LoanDetail
          label={t("outcome_loanterm")}
          value={t("outcome_months", { number: 60 })}
        />
        <LoanDetail
          label={t("outcome_firstpayment")}
          value={formatCurrency(selectedLoan.monthlyPayment)}
        />
        <LoanDetail
          label={t("outcome_interest")}
          value={`${selectedLoan.rate} %`}
        />
        <LoanDetail
          label={t("outcome_total")}
          value={formatCurrency(selectedLoan.totalRepayable)}
        />
      </List>

      <div className="fc-loan-approved__reference element-max-width">
        <p className="fc-loan-approved__reference__text">
          {t("outcome_reference")}
        </p>
        <p className="fc-loan-approved__reference__number">{referenceNumber}</p>

        <p className="fc-loan-approved__reference__email-text">
          {t("outcome_confirmation_text")}
        </p>
      </div>

      <div className="fc-loan-approved__next-step">
        <p>{t("outcome_nexttitle")}</p>
        <ul>
          {nextStepMessages.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <b>{t("outcome_nextsubtext_bullet_point")}</b>
      </div>

      <div className="fc-loan-approved__contact">
        <p className="fc-loan-approved__contact__header">
          {t("outcome_anyquestion")}
        </p>

        <EmailUs
          href={LOAN_APPROVED_DATA.emailUsHref}
          mailText={t("outcome_emailus")}
        />

        <p>{t("outcome_orcall")}</p>

        <CallUs
          href={LOAN_APPROVED_DATA.callUsHref}
          phoneText={LOAN_APPROVED_DATA.callUsPhoneText}
        />

        {disclaimerMessages.map((message, index) => (
          <p key={message} className="fc-loan-approved__contact__disclaimer">
            {message}
          </p>
        ))}
      </div>
    </StyledLoanApproved>
  );
};

export default LoanApproved;
